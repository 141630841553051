import { useEffect, useState } from "react";
import CountUp from "react-countup";
import Intro from "../../images/intro_1.webp";
import Logo from "../../images/logo-1.svg";
import Intro_2 from "../../images/intro_2.webp";
import MyTeam from "./myteam";
import { useRecoilState } from "recoil";
import { isFeedBackVisibleState } from "../../recoil/atom/isFeedBackVisible";

const MainPage = () => {
  const [shouldStartCounting, setShouldStartCounting] = useState(false);
  const [, setIsVisible] = useRecoilState(isFeedBackVisibleState);
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("li");

      if (element && window.scrollY + window.innerHeight >= element.offsetTop) {
        setShouldStartCounting(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openModal = () => {
    setIsVisible(true);
  };

  return (
    <div>
      <div
        className="bg-[#1F3645] lg:h-[570px] h-[460px] absolute w-full top-0 left-0 -z-10"
        rel="canonical"
      />
      <div className="bg-[#C5C5C5] w-full fixed top-0 left-0 right-0 bottom-0 -z-20" />
      <section className="flex justify-center">
        <div className="relative w-fit h-fit rounded-[30px] overflow-hidden">
          <img
            src={Intro}
            alt="Pushkarenko Intro"
            className="lg:w-[1094px] w-[350px] lg:h-[660px] h-[468px] object-cover lg:object-[80%_25%] object-[60%_35%]"
          />
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="lg:w-[1094px] lg:h-[660px]">
              <div className="animate-slide-left lg:pt-[50px] pt-[25px] pl-[42px]">
                <img
                  src={Logo}
                  alt="Logo Pushkarenko"
                  className="lg:w-[352px] w-[200px] lg:h-[132px] filter lg:block hidden"
                />
              </div>

              <div className="flex flex-col items-end text-right lg:pr-[57px] pr-[24px] lg:pt-[148px] pt-[198px]">
                <div className="animate-slide-right">
                  <div>
                    <h1 className="flex flex-col text-[#fff] font-raleway lg:text-[45px] font-normal lg:leading-[43px]">
                      Олександра <b>Пушкаренко</b>
                    </h1>
                  </div>
                  <div>
                    <span className="lg:w-[380px] w-[300px] flex flex-col lg:mt-[16px] text-[#fff] font-raleway lg:text-[19px] font-normal pt-2">
                      Практикуюча адвокатка, <br /> експертка Громадського
                      центру правосуддя Одеської області, <br /> авторка
                      юридичного блогу в Instagram з +20 тис. підписників
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    onClick={openModal}
                    className="w-[208px] realetive animate-pulse lg:w-[260px] lg:h-[52px] h-[30px] mt-[5px] bg-[#ffffff] text-[#000000] rounded-[32px] lg:text-[14px] text-[12px] font-normal leading-[18px] font-inter hover:scale-95 hover:bg-[#f6f6f6] transform transition-transform"
                  >
                    Умови та запис на консультацію
                  </button>
                </div>
                <div id="about"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center mt-[60px] mb-[106px]">
        <div className="flex flex-col lg:flex-row lg:w-[1101px] w-[350px]  rounded-[30px] bg-[#C5C5C5]">
          <div className="flex flex-col text-[#000] font-raleway lg:w-[595px] py-[15px] pl-[20px] pr-[25px] lg:text-[20px] font-light lg:leading-[38px]">
            <span className="flex justify-center lg:text-[45px] text-[25px] lg:leading-[43px]">
              Про мене
            </span>
            <p className="lg:pt-[25px] pt-[5px]">
              Вітаю, я Олександра Пушкаренко, практикуюча адвокатка з міста
              Одеса, але успішно консультую українців з усього світу онлайн або
              офлайн в нашому місті.
            </p>
            <p className="lg:pt-[20px] pt-[15px]">
              Криза 2022 року стала для мене можливістю надавати онлайн швидку,
              змістовну, а головне дієву допомогу українцям із найактуальніших
              питань сьогодення. Мною проведено 3000+ онлайн консультацій з
              питань перетин кордону чоловіками під час режиму воєнного стану,
              оформлення відстрочки під час мобілізації та правової допомоги
              військовим з питань проходження військової служби.
            </p>
            <p className="lg:pt-[20px] pt-[15px]">
              Я є авторкою юридичного блогу в інстаграм з 20+ тис. підписниками,
              де я щоденно транслюю актуальну обстановку на юридичному фронті,
              публікую сотні відгуків від моїх клієнтів, які безмежно мене
              мотивують та надихають.
            </p>
            <p className="lg:pt-[20px] pt-[15px]">
              Мої клієнти майже щодня перетинають кордон, оформлюються
              відстрочки, отримують консультації з питань проходження військової
              служби, тому мені є чим із вами поділитись.
            </p>
            <p className="lg:pt-[20px] pt-[15px]">
              Зі мною доступно, стримано та результативно – якщо Ви розділяєте
              мої цінності, буду рада знайомству та надати правову допомогу.
            </p>
          </div>
          <div className="flex items-center lg:pl-10 m-2">
            <img
              src={Intro_2}
              alt="Pushkarenko Intro 2"
              className="w-[441px] lg:h-[636px] object-cover rounded-[30px]"
            />
          </div>
        </div>
      </section>
      <section>
        <div id="teams"></div>
        <MyTeam />
      </section>
      <section className="">
        <div className="p-5">
          <ul className="flex flex-col items-center justify-center text-center gap-y-10 pb-14">
            <li>
              {shouldStartCounting && (
                <div className="flex justify-center items-center">
                  <div>
                    <CountUp
                      className="text-[28px] font-bold"
                      start={0}
                      end={3000}
                      delay={0}
                    />
                  </div>
                  <div className="text-[28px] font-bold">+</div>
                </div>
              )}
              <p className="text-[18px]">
                Консультації з питань перетину кордону, мобілізації, військовим
              </p>
            </li>
            <li>
              {shouldStartCounting && (
                <div className="flex justify-center items-center">
                  <div>
                    <CountUp
                      className="text-[28px] font-bold"
                      start={0}
                      end={500}
                    />
                  </div>
                  <div className="text-[28px] font-bold">+</div>
                </div>
              )}
              <p className="text-[18px]">Позитивних відгуків від клієнтів</p>
            </li>
            <li>
              {shouldStartCounting && (
                <div className="flex justify-center items-center">
                  <div>
                    <CountUp
                      className="text-[28px] font-bold"
                      start={0}
                      end={30}
                    />
                  </div>
                  <div className="text-[28px] font-bold">+</div>
                </div>
              )}
              <p className="text-[18px]">
                Онлайн вебінарів для бізнесу з питань мобілізації працівників
                (для 1000+ чоловіків)
              </p>
            </li>
            <li>
              {shouldStartCounting && (
                <div className="flex justify-center items-center">
                  <div>
                    <CountUp
                      className="text-[28px] font-bold"
                      start={0}
                      end={10}
                    />
                  </div>
                  <div className="text-[28px] font-bold">+</div>
                </div>
              )}
              <p className="text-[18px]">
                Офлайн вебінарів для бізнесу з питань мобілізації працівників в
                Одесі (для 500+ чоловіків)
              </p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default MainPage;
