import { useEffect, useState } from "react";

const Offer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const documentUrl = isMobile
    ? "https://docs.google.com/document/d/12AhJ5jsSxui3FMByumW21wUMmpyIUD12NXdO4bRkVJ0/mobilebasic"
    : "https://docs.google.com/document/d/12AhJ5jsSxui3FMByumW21wUMmpyIUD12NXdO4bRkVJ0/edit";

  return (
    <div className="bg-[#C5C5C5]">
      <div className="flex justify-center lg:text-[45px] text-[25px] lg:leading-[43px] py-10">
        <h1>Публічна Оферта</h1>
      </div>
      <div className="w-full min-h-[80vh] h-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center max-w-[1000px] w-full h-full p-3 leading-10">
          <iframe
            title="Word Document"
            src={documentUrl}
            width="100%"
            height="760px"
            style={{ background: "white" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Offer;
