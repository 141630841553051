import { useState, useEffect } from "react";
import Top from "../../images/top.webp";

export const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300;

      setIsVisible(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          className="fixed bottom-[70px] w-9 right-[10px] p-[10px] bg-[#1F3645] border rounded-full text-[15px] hover:bg-[#1c313e] transition-all duration-200 hover:"
          onClick={scrollToTop}
        >
          <img src={Top} alt="BackToTop" className="" />
        </button>
      )}
    </div>
  );
};
