import Service_1 from "../../images/services_1.webp";
import Service_2 from "../../images/services_2.webp";
import Service_3 from "../../images/services_3.webp";
import Service_4 from "../../images/services_4.webp";

const Services = () => {
  return (
    <div className="flex justify-center bg-[#C5C5C5]">
      <div className="lg:w-[1094px] w-[340px] py-[45px]">
        <section className="flex flex-col-reverse gap-10 lg:flex-row items-center">
          <img
            src={Service_1}
            alt="Pushkarenko Olexandra Service 1"
            className="w-[422px] lg:h-[621px] h-[480px] rounded-[30px]"
          />
          <div className="lg:w-[643px]">
            <h1 className="lg:text-[45px] text-[32px] font-light font-raleway lg:leading-[43px] justify-center flex lg:justify-start">
              Послуги
            </h1>
            <div className="flex pt-[16px]">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  01
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Перетин кордону чоловіками. <br /> Правова допомога
                </span>
                <p className="text-[13px] font-normal lg:leading-[21px] opacity-[0.7] font-popins">
                  Консультації з питань перетину кордону чоловіками у віці від
                  18 до 60 років під час воєнного стану в Україні, перелік
                  необхідних документів, юридичний супровід.
                </p>
              </div>
            </div>
            <div className="h-[1px] lg:w-[643px] bg-[#000000] opacity-[0.4]" />

            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  02
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Юридичний захист під час мобілізації
                </span>
                <p className="text-[13px] font-normal lg:leading-[21px] opacity-[0.7] font-popins">
                  Консультації з питань мобілізації, складання заяви про
                  оформлення відстрочки, допомога у зборі документів, оскарження
                  відмови в оформлені відстрочки, виїзд з клієнтом до ТЦК та СП.
                </p>
              </div>
            </div>
            <div className="h-[1px] lg:w-[643px] bg-[#000000] opacity-[0.4]" />
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  03
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Юридичний супровід щодо оформлення відстрочки
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Від консультації до оформленої відстрочки.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col lg:flex-row mt-[45px] gap-10 items-center">
          <div className="lg:w-[643px]">
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  04
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Оскарження висновків ВЛК
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Підготовка до проходження медогляду, аналіз медичних
                  документів із попереднім висновком про придатність до
                  військової служби, оскарження висновків ВЛК.
                </p>
              </div>
            </div>
            <div className="h-[1px] lg:w-[643px] bg-[#000000] opacity-[0.4]" />
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  05
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Захист прав військовослужбовців та членів їхніх сімей.
                  Юридичний супровід з питань проходження військової служби
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Від написання рапорту, звернення, скарги до звернення до суду.
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={Service_2}
              alt="Pushkarenko Olexandra Service 2"
              className="w-[422px] lg:h-[621px] h-[480px] rounded-[30px]"
            />
          </div>
        </section>

        <section className="flex flex-col-reverse lg:flex-row mt-[45px] gap-10 items-center">
          <img
            src={Service_3}
            alt="Pushkarenko Olexandra Service 1"
            className="w-[422px] lg:h-[621px] h-[480px] rounded-[30px]"
          />
          <div className="lg:w-[643px]">
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  06
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Проведення вебінарів для працівників компанії (бізнесу) з
                  питань мобілізації
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Порядок вручення повісток, відповідальність за неявку,
                  підготовка до призову, підстави для відстрочки, процедура
                  оформлення відстрочки з практичними порадами.
                </p>
              </div>
            </div>
            <div className="h-[1px] lg:w-[643px] bg-[#000000] opacity-[0.4]" />

            <div className="flex mt-5">
              <div className="py-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  07
                </span>
              </div>
              <div className="flex flex-col pb-[20px]">
                <div className="flex">
                  <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                    Юридичний супровід бізнесу
                  </span>
                </div>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Правовий аналіз договорів, підготовка звернень, скарг,
                  позовних заяв, представництво інтересів клієнта, тощо.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col lg:flex-row mt-[45px] gap-10 items-center">
          <div className="lg:w-[643px]">
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  08
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Представництво інтересів клієнта в суді
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Правнича допомога від подання позову до отримання рішення
                  суду.
                </p>
              </div>
            </div>
            <div className="h-[1px] lg:w-[643px] bg-[#000000] opacity-[0.4]" />
            <div className="flex">
              <div className="pt-[20px]">
                <span className="lg:text-[23px] font-semibold lg:leading-[32px] text-[#000] opacity-[0.34] lg:pr-[90px] pr-[30px] font-popins">
                  09
                </span>
              </div>
              <div className="flex flex-col pb-[46px]">
                <span className="lg:text-[26px] text-[18px] font-normal lg:leading-[40px] leading-[30px] pt-[10px] font-popins">
                  Послуги у сфері сімейного права
                </span>
                <p className="text-[13px] font-normal leading-[21px] opacity-[0.7] font-popins">
                  Розлучення без вашої участі, поділ майна подружжя та інші
                  пов’язані питання.
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={Service_4}
              alt="Pushkarenko Olexandra Service 2"
              className="w-[422px] lg:h-[621px] h-[480px] rounded-[30px]"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Services;
