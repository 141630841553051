import Instagram from "../../images/iconoir_instagram.svg";
import FaceBook from "../../images/ic_round-facebook.svg";
import Telegram from "../../images/ic_baseline-telegram.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isFeedBackVisibleState } from "../../recoil/atom/isFeedBackVisible";

const Footer = () => {
  const location = useLocation();
  const [, setIsVisible] = useRecoilState(isFeedBackVisibleState);

  const openModal = () => {
    setIsVisible(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToAnchor = (anchorId: string) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (location.hash === "#about") {
      scrollToAnchor("about");
    } else if (location.hash === "#teams") {
      scrollToAnchor("teams");
    }
  }, [location.hash]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div>
        <div className="bg-[#1F3645] h-[284px] flex justify-center font-raleway">
          <div className="max-w-[1100px] w-full h-[185px] flex justify-around Lg:gap-5 lg:gap-[157px] py-[50px] text-[#ffffff] text-[12px] lg:text-[18px]">
            <div>
              <ul className="flex flex-col gap-[10px]">
                <li>
                  <NavLink to={"/contacts"} onClick={scrollToTop}>
                    <button className="hover:underline hover:translate-x-1 transition-transform transform">
                      Контакти
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/#about"}
                    onClick={() => scrollToAnchor("about")}
                  >
                    <button className="hover:underline hover:translate-x-1 transition-transform transform">
                      Про мене
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/#teams"}
                    onClick={() => scrollToAnchor("teams")}
                  >
                    <button className="hover:underline hover:translate-x-1 transition-transform transform">
                      Наша команда
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/services"} onClick={scrollToTop}>
                    <button className="hover:underline hover:translate-x-1 transition-transform transform">
                      Послуги
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>

            <div>
              <ul className="flex flex-col gap-[10px]">
                <li>
                  <NavLink to={"/reviews"} onClick={scrollToTop}>
                    <button className="hover:underline hover:translate-x-1 transition-transform transform duration-200">
                      Відгуки клієнтів
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/offer"}>
                    <button className="hover:underline hover:translate-x-1 transition-transform transform">
                      Публічна оферта
                    </button>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={openModal}
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Умови консультації
                  </button>
                </li>
              </ul>
            </div>

            <div>
              <ul className="flex flex-col gap-[10px]">
                <li className="flex">
                  <img src={Instagram} alt="Instagram" className="pr-[10px]" />
                  <a
                    href="https://www.instagram.com/olexandra_pushkarenko_advokat/"
                    target="blank"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Instagram
                  </a>
                </li>
                <li className="flex">
                  <img src={FaceBook} alt="Facebook" className="pr-[10px]" />
                  <a
                    href="https://www.facebook.com/profile.php?id=100013535073380"
                    target="black"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Facebook
                  </a>
                </li>
                <li className="flex">
                  <img
                    src={Telegram}
                    alt="Telegram-канал"
                    className="pr-[10px]"
                  />
                  <a
                    href="https://t.me/+acXLq8a9RSljMGEy"
                    target="black"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Telegram-канал
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-[#C5C5C5] lg:h-[75px] h-[162px] flex lg:items-center p-5">
          <div className="flex justify-between w-full lg:px-[107px]">
            <div className="flex flex-col text-[12px] lg:text-[14px] font-light">
              <p>© {currentYear} «PUSHKARENKO&PARTNERS. LAW FIRM».</p>
              <p>Усі права захищені.</p>
            </div>
            <div className="text-[14px] lg:text-[18px] font-light flex lg:items-center text-center">
              <a
                href="https://www.google.com/maps/place/%D0%97%D0%BE%D0%BE%D0%BF%D0%B0%D1%80%D0%BA%D0%BE%D0%B2%D0%B0%D1%8F+%D1%83%D0%BB.,+4,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4447055,30.7480845,19.73z/data=!4m6!3m5!1s0x40c633ea23bdd649:0x8530676cab4235eb!8m2!3d46.4447526!4d30.7481255!16s%2Fg%2F11c43vfq5d?entry=ttu"
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                Адреса: Одеса, Зоопаркова, 4
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
